html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  color: rgb(69, 69, 69);
  box-sizing: border-box;
}
